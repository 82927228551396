@import "src/scss/variables.scss";

.ReactModal__Overlay {
  background: rgba(30, 30, 30, 0.7) !important;
  z-index: 999;
  width: 100%;
  height: 100vh;
  overflow: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.ReactModal__Content {
  width: auto !important;
  height: auto !important;
  padding: 1em 0 !important;
  position: static !important;
  background: none !important;
  border: none !important;
  overflow: visible !important;
  margin: auto;
  max-width: calc(100vw - 2em);
}

.modal {
  background: $v2area;
  border-radius: $v2borderRadius;
  padding: $v2halfGap;
  position: relative;
  max-width: 30rem;

  @media only screen and (min-width: $desktop) {
    max-width: 50rem;
  }

  &__close {
    cursor: pointer;

    svg {
      // width: 1.5em;
      // height: 1.5em;
      fill: #777;
      display: block;
    }
  }

  &__header {
    display: flex;
    align-items: center;
    margin-bottom: 1.5em;
    justify-content: space-between;

    &:empty {
      display: none;

      + .modal__content {
        margin-top: 0;
      }
    }
  }

  &__title {
    font-size: 18px;
    font-weight: bold;
    margin-right: 1.5em;
    display: flex;
    align-items: center;

    @media only screen and (max-width: 767px) {
      text-align: center;
    }

    &-icon {
      width: 3em;
      height: 2em;

      svg {
        width: 2em;
        height: 2em;
      }
    }
  }

  &__content {
    margin: $v2gap 0;

    video {
      max-width: 100%;
    }
  }

  &__footer {
    display: flex;
    justify-content: center;
    grid-gap: $v2gap;
  }

  &__paragraph {
    margin-bottom: $v2gap;

    &.center {
      text-align: center;
    }
  }
}