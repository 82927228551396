@use "sass:math";

$g: "Grafier";
$primary: #001253;
$primary-faded: #6d79a3;
$blue: #3355ff;
$grey: #c4c8d7;
$grey-hover: #959fd6;
$dark-gray: #3b3b3b;

$blue-light: #7ca1ff;
$blue-faded: #3455cb;
$blue-dark: #30489b;
$red: #f8364e;
$red-dark: #be0026;
$textHightlight: #B47021;
$silver: #d2d2d2;
$bluish: #ccd3f7;
$v2blueBg: #daf4ff;
$notSowhite: #fafafa;
$lightSilver: #F6F6F6;
$blue2: #f3f7ff;
$ddd: #ddd;

$info: rgba(227, 242, 253, 1);

$v2links: #30489b; //$blue-dark;
$v2linksHover: $blue-faded;
$v2red: #b42b2b;
$v2green: #018100;
$v2btn: #313e8b;
$v2btnSecondary: #949bbe;
$v2btnHover: #4051b6;

$v2ctlHeight: 2.5em;
$v2ctlBorder: $grey;
$v2ctlDisabledBorder: #aaa;
$v2ctlDisabledBg: #eee;
$v2ctlDisabledText: #aaa;
$v2ctlBg: #c0c4d3;
$v2price: #182057;
$v2priceSize: 1.5em;
$v2placeholder: $grey;
$v2disabled: $bluish;
$v2moreArrow: #c4c4c4;
$v2header: #212121;
$v2black: #212121;
$v2hline: #dfdfdf;
$v2area: $notSowhite;
$v2actionIcon: $silver;
$v2navActive: $primary;
$v2inactive: $grey;
$v2inactiveHover: $grey-hover;
$v2activeBlock: #313e83;
$v2tableHead: $bluish;
$v2NFT: #949bbe;
$v2modalGap: 3em;
$v2dealSideColor: #eaeaea;

$v2sidebar: $v2btn; //#2f3e7f;

$v2lineHeight: 1.5rem;
$v2halfLineHeight: math.div($v2lineHeight, 2);
$v2InputPadding: 0.75em;

// 14px
$v2fontSmall: 0.875rem;
$v2fontBig: 1.125rem;
$v2gap: 1.5rem;
$v2halfGap: math.div($v2gap, 2);

$v2mobileBorder: #e0e2f7;

$v2landingHeadingSmall: 2rem;
$v2landingText: 1.75rem;
$v2landingText: 1.25rem;
$v2landingBtnFontSize: 1.5rem;
$v2landingMenuItemFontSize: 1.25rem;
$v2landingMenuItemColor: #979797;

// FONT-SIZE

// BORDER RADIUS
// Может использовать rem?
$v2borderRadius: 0.5rem;
$v2borderRound: 50%;

// COLORS

// GAP

// MARGIN

// BREAKPOINTS

$mobile: 320px;
$smallTablet: 768px;
$largeTablet: 992px;
$desktop: 1280px;


// RE-DESIGN

$v3primary: #0671E0;

$v3whiteColor: #FFFFFF;
$v3blackColor: #212121;


// buttons
$v3disabledColor: #F4F4F4;
$v3borderColor: #F8F8F8;
$v3borderHoverColor: #F0F0F0;
$v3borderRadius: 8px;
$v3borderRound: 50%;

$v3fieldDisabledColor: #FAFAFA;
$v3radioDisabledBackground: #EEF5FC;
$v3radioDisabledInner: #ABBED1;

// Потом заменить на градацию
$v3padding: 18px;
$v3padding12px: 12px;


// Много где используется, надо переименовать
$v3primaryButtonColor: #313E8B;
$v3radioButtonHoverColor: #3948A5;
$v3primaryHoverButtonColor: #3949A5;
$v3headerHeight: 64px;
// После изменения названия пройтись грепом, через Ф2 не меняет
$v3neopoznanniyCvet1: #ADADAD;
$v3dangerousColor: #E02B1D;
$v3containerBorder: #EFEFEF;
$v3headerLinkColor: #717171;
$v3headerLinkColorActive: #000423;


// $v3containerDesktop: 1420px;
$v3containerDesktop: 1920px;
$v3containerLargeTablet: 944px;
$v3conatinerSmallTablet: 690px;

$v3desktopFontSize1: 24px;
$v3desktopFontSize2: 18px;
$v3desktopFontSize3: 16px;
$v3desktopFontSize4: 14px;
$v3desktopFontSize5: 12px;

$v3requiredStarColor: #F44336;

$v3successFieldColor: #6CBC6E;

// notifications
$v3success: #6CBC6E;
$v3warning: #E48900;
$v3error: #F44336;
$v3info: #0085FF;
// Не опознаны
$v3labelFontColor: #4D4D4D;
$v3paginationBorderColor: #89939E;
$v3paginationBackgroundColor: #F5F7FA;
$v3neopoznanniyCvet2: #80849D;
$v3secondaryIconColor: #80A9DA;

$v3photoIconColor: #D1D1D1;


$v3productCharacteristicsBackground: #F6F6F6;


// buttons
// secondary buttons
$v3secondaryButtonBorderColor: #DCDEE4;
$v3secondaryButtonFontColor: #696F8C;

// z-index:
$v3zIndexMenu: 9999;
$v3zIndexNotificationModal: 99999;
$v3zIndexHeader: 9999;
$v3zIndexPageLoader: 99999;

$v3optionfieldBorderRadius: 40px;
$v3optionfieldGap: 5px;
// Не опознан


$v3sideBarMenuSmallWidth: 47px;

$v3wrapperPadding: 20px;


// z-index rules:
// 
$v3zIndex9: 9;
$v3zIndex99: 99;
$v3zIndex999: 999;
$v3zIndex9999: 9999;
$v3zIndex99999: 99999;
$v3zIndex9: 9;
$v3zIndex9: 9;


$v3fontLight: 300;