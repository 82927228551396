@import "src/scss/variables.scss";

.cookies {
  font-size: $v3desktopFontSize5;
  opacity: 0;
  animation-name: appear;
  animation-delay: 500ms;
  animation-duration: 2.5s;
  animation-fill-mode: forwards;


  position: fixed;
  bottom: 0;
  //left: 0;
  width: 45%;

  @media all and (max-width: $smallTablet) {
    width: 100%;
  }
  right: 0;
  z-index: 99999;

  margin: 0 auto;
  padding: $v2halfGap;

  display: flex;
  align-items: center;
  justify-content: center;

  max-width: 1440px;


  &__buttons {
    display: flex;
    justify-content: center;
    align-items: center;

    button {
      background: $primary;
      color: #fff;
    }
  }

  &__container {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    border-radius: $v3borderRadius;
    background-color: $v3fieldDisabledColor;
    border: 1px solid $v3radioDisabledBackground;
    padding: $v2halfGap;
    grid-gap: $v2halfGap;
    // margin: 0 auto;
    width: 100%;

    @media only screen and (min-width: $desktop) {
      flex-direction: unset;
    }
  }

  &__text {
    a {
      margin-top: .5rem;
    }
  }
}
