@import "src/scss/variables.scss";

.article {
  &__title {
    font-size: $v3desktopFontSize1;
    color: $v2black;
    font-weight: normal;
    padding-bottom: 0.5rem;
    display: flex;
    align-items: center;
  }

  &__content {
    text-align: justify;
    padding-left: 1em;
    padding-right: 1em;

    p {
      width: 90%;
    }
  }
}