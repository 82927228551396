@import "src/scss/variables.scss";

.button {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: $v2halfGap;
  height: min-content;
  font-size: $v3desktopFontSize4;

  text-align: center;
  cursor: pointer;

  border-radius: $v3borderRadius;
  border: 1px solid transparent;

  transition: 200ms;
  box-sizing: border-box;
  white-space: nowrap;

  &--normal {
    width: 100%;

    @media only screen and (min-width: $smallTablet) {
      width: unset;
      min-width: 12rem;
    }
  }

  &--max {
    width: 100%;
  }

  &--min {
    width: 100%;

    @media only screen and (min-width: $smallTablet) {
      width: fit-content;
    }
  }

  &--primary {
    background: $v3primaryButtonColor;
    border: 1px solid $v3primaryButtonColor;
    color: $v3whiteColor;
    cursor: pointer;

    &:active {
      background-color: #3948A5;
      border: 1px solid #3948A5;
    }

    &:hover {
      background-color: $v3primaryHoverButtonColor;
      border: 1px solid $v3primaryHoverButtonColor;
    }

    & .button__icon {
      stroke: white;
    }
  }

  &--secondary {
    border: 1px solid $v3secondaryButtonFontColor;
    background-color: $v3whiteColor;
    color: $v3secondaryButtonFontColor;
    font-weight: 400;
    cursor: pointer;

    & .button__icon {
      stroke: $v3secondaryButtonFontColor;
      fill: transparent;
    }
  }

  &--tertiary {
    background-color: transparent;
    border: 1px solid transparent;
    color: $v3primary;
    cursor: pointer;

    & .button__icon {
      stroke: $v3primary;
    }
  }

  &--link {
    background: transparent;
    color: $v3primary;
    cursor: pointer;

    svg {
      stroke: $v3primary;
    }

    &:hover {
      text-decoration: none;
    }
  }

  &--primary:disabled,
  &--secondary:disabled,
  &--tertiary:disabled,
  &--link:disabled {
    background-color: $v3disabledColor;
    border: 1px solid $v3disabledColor;
    color: $v3secondaryButtonFontColor;
    cursor: not-allowed;
  }

  // size
  &--s {
    padding: 10px 16px;
  }

  &--m {
    padding: 12px 18px;
  }

  &--l {
    padding: 14px 20px;
  }

  &--loading {
    cursor: progress;

    & .button__icon {
      animation: loaderSpin 2s infinite linear;
    }
  }

  &__icon {
    flex-shrink: 0;

    &--left {
      order: -1;
    }

    &--right {
      order: 0;
    }
  }

  &--no-padding {
    padding: 0;
  }

}

@keyframes loaderSpin {
  0% {
    rotate: 0deg;
  }

  100% {
    rotate: 360deg;
  }
}