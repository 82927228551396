@import "./variables.scss";
@import 'node_modules/react-modal-video/scss/modal-video.scss';

@font-face {
  font-family: "JSF";
  src: url("assets/fonts/jsf-medium-webfont.woff2") format("woff2"),
  url("assets/fonts/jsf-medium-webfont.woff") format("woff");
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "JSF";
  src: url("assets/fonts/jsf-regular-webfont.woff2") format("woff2"),
  url("assets/fonts/jsf-regular-webfont.woff") format("woff");
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "JSF";
  src: url("assets/fonts/jsf-lightx-webfont.woff2") format("woff2"),
  url("assets/fonts/jsf-lightx-webfont.woff") format("woff");
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

*:focus {
  outline: none;
}

body {
  //min-width: 400px;
  font: 300 18px "JSF",
  sans-serif;
  line-height: 1.5;
  color: $primary;
}

h1 {
  font-size: 1.5em;
  line-height: 1.5em;
}

h2 {
  font-size: 1.125rem;
  line-height: 1.5rem;
}

h3 {
  font-size: 1rem;
  font-weight: bold;
  margin: 0;
}

a {
  display: inline-block;
  font: inherit;
  color: $v2links;
  transition: 300ms;
  text-decoration: none;

  &:hover {
    text-decoration: none;
    color: $v2linksHover;
  }
}

input,
button,
textarea {
  font: inherit;
}

img {
  max-width: 100%;
  max-height: 100%;
  height: auto;
}

svg {
  max-width: 100%;
  fill: $primary;
}

a img {
  border: none;
  outline: none;
}

ul {
  margin: 0;
  padding: 0;
  list-style: none;
}

.hidden {
  display: none !important;
}

.container {
  width: 100%;
  max-width: 100%;
  padding: 0 0.75em;
  box-sizing: border-box;
  margin: 0 auto;

  @media only screen and (min-width: $largeTablet) {
    max-width: 944px;
  }

  @media only screen and (min-width: $desktop) {
    max-width: 1420px;
  }
}

.title {
  font-size: 1.5rem;
  font-weight: 400;

  @media only screen and (max-width: 767px) {
    font-size: 1.2rem;
  }
}

.wrapper {
  display: flex;
  flex-direction: column;
  padding-top: $v3headerHeight;
  min-height: calc(100dvh - $v3headerHeight);
  overflow: hidden;
}

@keyframes appear {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

.appear {
  animation: appear forwards 800ms ease-in-out;
}

.intercom-lightweight-app {
  display: none;

  @media only screen and (min-width: $smallTablet) {
    display: unset;
  }
}

.flex--row {
  display: flex;
  flex-direction: row;
  grid-gap: $v2gap;
  width: 100%;
}

.flex--column {
  display: flex;
  flex-direction: column;
  grid-gap: $v2gap;
}