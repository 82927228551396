@import "src/scss/variables.scss";

@media only screen and (min-width: $desktop) {
  .main {
    position: fixed;
    width: 100%;
    top: $v3headerHeight;
  }
}

.landing-grid {
  display: grid;
  position: relative;
  margin: unset;

  @media only screen and (min-width: $desktop) {
    grid-template-columns: 1fr auto;
  }
}

.slider-container {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  flex-grow: 2;

  @media only screen and (min-width: $desktop) {
    z-index: 200;
    height: calc(100dvh - $v3headerHeight);
    display: grid;
    overflow: none;
  }

  &__footer {
    display: flex;
    //align-items: flex-end;
    gap: $v2halfGap;
    padding: $v2halfGap 0 3rem 0;

    @media only screen and (min-width: $smallTablet) {
      padding: $v2gap 0;
    }

    @media only screen and (min-width: $desktop) {
      padding: $v2halfGap 0;
    }
  }
}

.sidebar {
  display: none;
  position: relative;
  z-index: 150;

  &--first-slide {
    @media only screen and (min-width: $desktop) {
      display: flex;
      align-items: center;
    }
  }

  &--not-first-slide {
    @media only screen and (min-width: $desktop) {
      display: flex;
      align-items: flex-start;
      padding-top: 156px;
      flex-direction: column;
    }
  }

  &__menu {
    flex-grow: 1;
  }

  .sidebar__list {
    position: relative;
    display: grid;
    font-size: $v3desktopFontSize3;
    color: $v2landingMenuItemColor;
    padding: 0 $v2gap;
    border-left: 1px solid $v2landingMenuItemColor;
  }

  .sidebar__item {
    cursor: pointer;
    user-select: none;

    &:not(:first-child),
    &:not(:last-child) {
      padding-bottom: 1.25rem;
    }

    &:hover {
      color: #555;
    }

    &--current {
      color: #000;
    }
  }

  &__company-name {
    font-weight: 500;
  }

  &__company-information {
    display: flex;
    color: $v2landingMenuItemColor;
    font-size: $v3desktopFontSize5;
    padding: 0 $v2gap;
    border-left: 1px solid $v2landingMenuItemColor;
    margin-bottom: 14px;
    flex-direction: column;
    grid-gap: $v2halfGap;
  }
}

.landing-text {
  font-size: $v3desktopFontSize1;
}

.p-plus-clm {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;

  @media only screen and (min-width: $desktop) {
    min-height: calc(100dvh - $v3headerHeight);
    position: absolute;
    top: 0;
    left: 0;
  }

  & .slider-content > p {
    font-weight: normal;
    font-size: $v3desktopFontSize2;
  }

  & .slider-content--what-we-are > p {
    font-weight: lighter;
  }
}

.slider__slide {
  width: 100%;
  display: grid;
  margin: 0 auto;

  position: static;
  height: auto !important;
  pointer-events: auto;

  .slide {
    position: relative;
    padding-bottom: 2rem;
  }

  @media only screen and (min-width: $desktop) {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    opacity: 0;
    pointer-events: none;
  }

  @media only screen and (max-width: 600px) {
    margin: 0;
    width: 100% !important;
  }

  height: calc(100dvh - $v3headerHeight);
  transition: all 0.5s ease 0s;

  &--current {
    opacity: 1;
    transition: all 0.5s ease 0s;
    z-index: 100;
    pointer-events: auto;
  }
}

.slider-geometry {
  display: flex;
  grid-gap: 3rem;
}

.slider-geometry__slides {
  width: 75%;

  @media only screen and (max-width: 1100px) {
    width: 100%;
  }
}

.slider-geometry__nav {
  flex-grow: 1;
}

.wrapper.path-landing {
  min-height: 100vh;

  main.main {

    @media screen and (min-width: $desktop) {

      &:after {
        display: block;
        content: "";
        position: fixed;
        top: 0;
        left: 0;
        width: 100vw;
        height: 100vh;
        z-index: -1;
        background: url("../../assets/images/wingform-background.png") 0 0 no-repeat;
        background-size: auto 100vh;
      }
    }
  }

  .footer__inner {
    padding: 0;
  }
}

.slider__slide--current,
.slider__slide {
  .slider-heading h1 {
    position: relative;

    @media only screen and (max-width: $desktop) {
      z-index: 100;
      position: relative;

      &:before {
        content: "";
        height: 100%;
        width: 100vw;
        left: 0;
        position: absolute;
        z-index: 0;
        background: #fff;

      }

      &.inView-2 {
        position: fixed;
        width: calc(100vw - 3rem);
        top: 64px;
      }

      &.inView-3 {
        position: absolute;
        bottom: 0;
      }
    }

    .pplz {
      position: absolute;
      height: 3rem;
      width: 11rem;

      @media only screen and (max-width: 1300px) {
        height: 2rem;
        width: 7.5rem;
      }

      &.fademe {
        opacity: 0;
        transition: opacity 0.25s ease-in-out 0s;
      }

      img {
        height: 3rem;
        width: auto;
        display: block;

        @media only screen and (max-width: 1300px) {
          height: 2rem;
        }
      }

      z-index: 100;
      top: 0;
    }
  }

  .airplaned .word {
    white-space: nowrap;
  }

  .airplaned span {
    transform: scale(0);
    color: #fafafa;
  }

  .airplaned span.passed {
    animation: 1s charCloud;
    transform: scale(1);
    color: $primary;
    display: inline-block;
  }
}


@keyframes charCloud {
  0% {
    opacity: 0;
    transform: scale(0.1);
    color: #bbb;
  }

  60% {
    opacity: 75;
    transform: scale(0.8);
    color: #bbb;
  }

  100% {
    opacity: 1 !important;
    transform: scale(1);
    color: $primary;
  }
}

.hide-it {
  @media only screen and (max-width: 1100px) {
    display: none;
  }
}

@media all and (max-width: $desktop) {
  .slide--main-slide {
    min-height: calc(100vh - 64px);
    justify-content: center;
    display: flex;
    flex-direction: column;
  }

  .slide--main-slide .slider-content {
    flex-grow: 0;
    padding: 1.5rem 0;
  }

  .slider-content {
    padding-top: 1.5rem;
  }
}

@media only screen and (min-width: $desktop) {
  .slide {
    min-height: calc(100dvh - $v3headerHeight);
    display: flex;
    flex-direction: column;
  }


  .slide--main-slide {
    justify-content: center;

    .slider-content {
      flex-grow: 0;
    }

    .slider-heading {
      padding-bottom: 0;
    }
  }
}

.landing__list {
  list-style: disc;
  display: flex;
  flex-direction: column;
  gap: $v2halfGap;
  padding-left: 2rem;

  @media only screen and (max-width: 1199px) {
    padding-left: 1.25rem;
    padding-bottom: 1rem;
  }

  .landing__item {

    &::marker {
      @media only screen and (max-width: 1199px) {
        color: $primary;
      }
    }
  }
}

.article__container {
  display: flex;
  flex-direction: column;
  grid-gap: $v2gap;

  &--twoClm {
    display: grid;
    grid-template-rows: max-content;

    @media only screen and (min-width: $smallTablet) {
      grid-template-columns: repeat(2, 1fr);
    }
  }
}

.slider-heading {
  width: 100%;
  display: grid;
  align-items: flex-end;
  //padding: 2rem 0;
  // flex-grow: 1.5;

  @media only screen and (min-width: $desktop) {
    padding: 3rem 0;
    line-height: 10rem;
  }

  h1 {
    text-transform: uppercase;
    font-size: 2rem;
    line-height: 1.25;

    @media only screen and (min-width: $desktop) {
      font-size: 3rem;
    }
  }
}

.slider-content {
  display: grid;
  grid-gap: $v2gap;
  flex-grow: 2;

  @media only screen and (min-width: $largeTablet) {
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: max-content;
  }

  &--what-we-are {
    & > p {
      font-weight: lighter;
    }
  }
}

.greeting {

  @media only screen and (min-width: $desktop) {
    position: absolute;
    top: 0;
    left: 0;
  }

  width: 100%;

  display: flex;
  flex-direction: column;
  justify-content: center;

  .slider-heading,
  .slider-container__footer,
  .slider-content {
    flex-grow: 0;
  }

  .slider-container__footer {
    padding: $v2gap 0;

    @media only screen and (min-width: $desktop) {
      padding-top: $v2gap;
    }
  }
}

.grid-4clm {
  height: auto;
  width: 100%;
  display: flex;
  flex-direction: column;

  & .article__container {
    flex-grow: 2;
  }

  @media only screen and (min-width: $desktop) {
    min-height: calc(100dvh - $v3headerHeight);
    position: absolute;
    top: 0;
    left: 0;
  }
}

.person {
  display: flex;
  grid-gap: 1.5rem;
  flex-direction: column;

  @media all and (min-width: $desktop) {
    flex-direction: row;
  }

  &__description {
    display: flex;
    flex-direction: column;
  }

  &__name {
    font-size: 28px;
    font-weight: bold;
    color: #212121;
  }

  &__job-title {
    font-size: 20px;
    font-weight: bold;
    color: #979797;
  }

  &__socials {
    margin-top: 1rem;
    display: flex;
    grid-gap: 1rem;
  }
}

.slide--partners {
  .slider-content {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }


}

.partners__logo {
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  width: 100%;
  height: fit-content;
  grid-gap: 2rem;
  justify-content: center;
  align-items: center;

  @media all and (min-width: $largeTablet) {
    justify-content: flex-start;
    grid-template-columns: repeat(2, 1fr);
  }

  @media all and (min-width: $desktop) {
    grid-template-columns: repeat(3, 1fr);
  }
}